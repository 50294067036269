'use strict';

angular.module('kljDigitalLibraryApp')

.controller('SubscriptionCtrl', ["$rootScope", "$scope", "$state", "$cookies", "$stateParams",
    "commonVariables", "appConfig", "SubscriptionService", "guestUserService", "guestAccountService", "guestLoginService", "AccountDetailsService",
    "OrderService", "ngToast", "Auth", "MainService",
    function($rootScope, $scope, $state, $cookies, $stateParams,
        commonVariables, appConfig, SubscriptionService, guestUserService, guestAccountService, guestLoginService, AccountDetailsService,
        OrderService, ngToast, Auth, MainService) {



        $(window).scroll(function() {
            if ($(window).width() > 767) {
                if ($(window).scrollTop() > 1) {
                    $(".menuholder").addClass("affix");
                    // alert('scroll');
                } else {
                    $(".menuholder").removeClass("affix");
                }
            }


        });
        //Scroll top
        $(document).ready(function() {
            $(this).scrollTop(0);
        });
        /*Recaptcha*/
        var onSubmit = function(token) {
            console.log('success!', token);
            $scope.googleToken = token;
        };
        var onloadCallback = function() {
            grecaptcha.render('submit-captcha', {
                'sitekey': '6Ler9RoTAAAAAGIQw9A3ann6g5de5-MOWRPPxxSd',
                'callback': onSubmit
            });
        };
        // setTimeout(onloadCallback, 0)
        /*End*/


        var init = function() {
            getStateCityInfo();
            $scope.currentUrl = $state.current.name;
            if ($state.current.name === 'main.subscriptions') {
                getSubscriptions();
            }
            if ($stateParams.id) {
                getSubscription();
                // getStateCityInfo();
            }
            console.log("state-->", $state);
        }

        var getSubscriptions = function() {
            return SubscriptionService.availablePlans()
                .then(function(response) {
                    $scope.subscriptions = response;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        var getSubscription = function() {
            return SubscriptionService.getSubscriptionDetails($stateParams.id)
                .then(function(data) {
                    $scope.planDetails = data;
                    if (data.price) {
                        $scope.order = {
                            subTotal: data.price.after_discount,
                            tax: ((data.price.after_discount * data.tax) / 100)
                        };
                        $scope.order.total = $scope.order.subTotal + $scope.order.tax;
                    }
                })

            .catch(function(error) {

            });
        }

        var getStateCityInfo = function() {
            return MainService.getList()
                .then(function(response) {
                    $scope.citiesList = response.cities;
                    $scope.statesList = response.states;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        var getPrice = function(body) {
            var token = guestLoginService.getToken();
            return guestUserService.getPrice(body, token);
        }
        $scope.applyCoupon = function() {
            $scope.apply = true;
        }

        //Apply coupon code
        $scope.applyCode = function(code) {
           
            $scope.couponApplied = false;
            $scope.apply=false;
            getPrice(getPriceApiBody(code))

            .then(function(coupon) {
                $scope.appliedCode = code;
                $scope.order = coupon;
                $scope.couponApplied = true;
                $scope.couponMsg = "";
            })

            .catch(function(error) {
                console.log("tere", error.data);
                $scope.couponMsg = error.data;
            })
        }

        $scope.submitForm = function(valid, details) {
           
            $scope.submit = true;
            if (valid) {
                AccountDetailsService.create({
                        user: details
                    })
                    .then(function(response) {
                        $scope.mobile_number = response.data.mobile_number;
                        $scope.showOTP = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                        ngToast.danger('Unable to save details');
                    });
            }
        }

        var getPriceApiBody = function(code) {

            var body = {};
            body.subscriptionId = $stateParams.id;
            body.code = code;
            return body;
        }

        $scope.removeCoupon = function() {
            document.getElementById("coupon_textbox").value = "";
            $scope.couponApplied = false;
            $scope.appliedCode = "";
            $scope.couponCode = undefined;
            getPrice(getPriceApiBody($scope.couponCode))

            .then(function(coupon) {
                $scope.order = coupon;
                // ngToast.create('Coupon has been removed!');
            })

            .catch(function(error) {
                ngToast.danger('Error in removing coupon code!');
            })

        }

        $scope.registeredUser = function(mobile_number) {
            $scope.submitted = true;
            if (mobile_number) {

                Auth.registerdUser(mobile_number)

                .then(function(data) {

                    $scope.showOTP = true;
                    $scope.isregisteredUser = true;
                    $scope.generateOTP(mobile_number);
                })

                .catch(function(error) {
                    $scope.isregisteredUser = false;
                });
            } else {
                $scope.showOTP = false;
            }
        }

        $scope.generateOTP = function(mobile_number) {

            if (mobile_number) {

                Auth.generateOTP(mobile_number, $scope.googleToken)

                .then(function(success) {
                    // grecaptcha.reset();
                    $scope.showOTP = true;
                })

                .catch(function(error) {
                    // grecaptcha.reset();
                    console.log(error);
                });
            }
        }

        $scope.verifyOTP = function(mobile_number, otp) {
            $scope.show = true;
            if (mobile_number && otp) {
                var body = {
                    user: {
                        mobile_number: mobile_number,
                        otp: otp
                    }
                };
                guestAccountService.verifyUser(body)
                    .then(function(response) {
                        guestLoginService.addToken(response.data.token);
                        $scope.success = true;
                        getPrice(getPriceApiBody())
                            .then(function(data) {
                                $scope.order = data;
                            })
                            .catch(function(error) {
                                console.log("tere", error.data);
                                $scope.couponMsg = error.data;
                            })
                    })

                .catch(function(error) {
                    if (error.data == 'Unauthorized') {
                        //redirect to home 
                        $scope.unAuth = true;
                    } else {
                        $scope.wrongOtp = true;
                    }
                })
            }

        }

        function createPaymentFormData(data) {

            var form = document.createElement("form"),
                node = document.createElement("input");


            form.action = data.payment_url;
            form.method = "POST";

            node.name = "key";
            node.value = data.key;
            form.appendChild(node.cloneNode());

            node.name = "hash";
            node.value = data.hash;
            form.appendChild(node.cloneNode());

            node.name = "txnid";
            node.value = data.txnid;
            form.appendChild(node.cloneNode());

            node.name = "amount";
            node.value = data.amount;
            form.appendChild(node.cloneNode());

            node.name = "firstname";
            node.value = data.firstname;
            form.appendChild(node.cloneNode());

            node.name = "email";
            node.value = data.email;
            form.appendChild(node.cloneNode());

            node.name = "phone";
            node.value = data.phone;
            form.appendChild(node.cloneNode());

            node.name = "productinfo";
            node.value = data.productinfo;
            form.appendChild(node.cloneNode());

            node.name = "surl";
            node.value = data.surl;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "furl";
            node.value = data.furl;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "service_provider";
            node.value = data.service_provider;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "udf1";
            node.value = data.udf1;
            form.appendChild(node.cloneNode());

            node.name = "udf2";
            node.value = data.udf2;
            form.appendChild(node.cloneNode());

            form.style.display = "none";
            document.body.appendChild(form);

            return form;

        }



        //Make payment
        $scope.makePayment = function(plan, couponCode, guest) {
            var token = guestLoginService.getToken();
            $scope.payment = {};
            guestUserService.getPaymentDetails(plan._id, couponCode, true, token)

            .then(function(data) {
                    console.log("data----", data);
                    if (plan.plan_type === 'Trial') {
                        $scope.payment.success = true;
                        $scope.payment.alert = true;
                        $scope.payment.message = data;
                    } else {
                        let form = createPaymentFormData(data);
                        form.submit();
                    }
                })
                .catch(function(error) {
                    $scope.payment.success = false;
                    $scope.payment.alert = true;
                    $scope.payment.message = error.data;
                })
        }



        init();


    }
]);